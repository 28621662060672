import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Category from "../components/Category";
import HomeBannerOverlay from "../components/HomeBannerOverlay";
import About from "../components/About";


const Home = () => {
    return (
        <div>
            <Navbar />
            <HomeBannerOverlay imageUrl="https://ik.imagekit.io/dnddecpho/misc/banner1_dh8O5DIef.png?updatedAt=1696002756452" title="Great Marketing Add Great Values "></HomeBannerOverlay>
            <About />
            <Category />
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Home;
