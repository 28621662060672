import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column" })}
`;
const Title = styled.h1`
    padding: 0 2rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    color: #1f2f4d;
    text-align: center;
    margin: 0rem 0rem 1rem 0;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
`;

const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.6rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: "1" ${mobile({ height: "80vh" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/partner_hChsPxCRr.png?updatedAt=1696002682532");
    background-size: cover;
    height: 80vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/vision_5acMJ4Evw.png?updatedAt=1696003188469");
    background-size: cover;
    height: 80vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/contribution_QEDHdHsCD.png?updatedAt=1695896304240");
    background-size: cover;
    height: 80vh;
    background-position: center;
    ${mobile({
        display: "none",
    })}
`;

const About = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>We're Your Partner In Success</Title>
                    <AboutContainer>
                        <Desc>
                            Quantract is an IT software, Digital Marketing, and
                            Digital Transformation-focused digital marketing
                            firm. We are committed to staying ahead of the curve
                            in technology and constantly strive to pioneer new
                            advancements.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Title>Vision and Mission</Title>
                    <AboutContainer>
                        <Desc>
                            Our Vision is for Quantract to become the eminent
                            partner to our users and market players seeking to
                            be at the technological forefront of their
                            respective industry.
                            <br></br>
                            <br></br>
                            Our Mission is to give our users the competitive
                            edge though our proprietary technologies that would
                            help accelerate their businesses.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftA>
                    <Title>Our Contributions To The World</Title>
                    <AboutContainer>
                        <Desc>
                            In the current market landscape, success hinges on
                            speed, adaptability, and technological prowess. We
                            aspire to enhance the world through our cutting-edge
                            software solutions and meaningful contributions in
                            various related domains.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageC></ImageC>
            </Container>
        </>
    );
};

export default About;
