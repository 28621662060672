import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import About from "../components/About";
import BannerOverlay from "../components/BannerOverlay";

const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <BannerOverlay imageUrl="https://ik.imagekit.io/dnddecpho/misc/about_vZNS4Azne.png?updatedAt=1695901081225" title="About Us"></BannerOverlay>
            <About />
            <Newsletter />
            <Footer />
        </div>
    );
};

export default AboutUs;
