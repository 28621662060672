import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    width: 100%;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", gridTemplateColumns: "100%" })}
`;
const Title = styled.h1`
    margin: 0 3rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.5rem",
    })}
`;

const Subtitle = styled.h2`
    margin: 0 3rem;
    font-family: FuturaPT-Demi;
    font-size: 1.5rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.4rem",
        padding: "0rem",
    })}
`;

const AboutContainer = styled.div`
    display: block;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    min-height: 60vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    order: "1";
     ${mobile({ height: "100%", padding: "20px 0" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/m1-production_cAwQm0EzJY.png?updatedAt=1696001490179");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/m2_uCL_DFQVC6.png?updatedAt=1696001489879");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/dev4_9t7y2P0hT.png?updatedAt=1696002170542");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "3",
        height: "50vh",
        width: "100vw",
    })}
`;

const Development = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>Mobile First Concept</Title>
                    <AboutContainer>
                        <Subtitle>
                            Design Mobile Apps Based on Business Strategy
                        </Subtitle>
                        <Desc>
                            Mobile apps account for over half of all time spent
                            consuming digital media. They’re resources that
                            bring you closer to your users, and help you to
                            manage your assignment, internally. They allow
                            better functionality, streamlining your office and
                            integrating your workload into a centralized app,
                            meaning you get more than just a fun new app – you
                            get something preferable to you, your employees and
                            customers.
                        </Desc>
                        <Desc>
                            Well, the good news is that it is one of our
                            services. We are expertise in both native and
                            cross-platform technologies, we always find the
                            right balance among price, quality, and project
                            requirements so we can offer the best solution for
                            your particular situation.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Title>Our Mobile App Development Process</Title>
                    <Subtitle>Our comprehensive mobile app development strategy ensures a perfectly crafted website for your business.</Subtitle>
                    <br></br>
                    <br></br>
                    <Subtitle>1. Consultation</Subtitle>
                    <AboutContainer>
                        <Desc>
                            A good place to start is through discovery and
                            market research. We will discuss your target
                            audience, competitors and mobile marketing plan to
                            identify latest features to fix in the app. Informed
                            feedback in these early stage will pave the way for
                            a smoother development process. We will also
                            consider your budget and timeline for development.
                        </Desc>
                    </AboutContainer>
                   
                </LeftA>
            </Container>
            <Container>
                <LeftA>
                <Subtitle>2. Development</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Now it’s time to begin sketching out your app and
                            creating a storyboard to develop a clear picture of
                            how your ideas and proposed features will fuse
                            together into a functional app. Later, the
                            connection between the screen and navigation through
                            the app are demonstrated by focusing on user
                            experience followed by programming and coding phase.
                        </Desc>
                    </AboutContainer>
                    <Subtitle>3. Final Design</Subtitle>
                    <AboutContainer>
                        <Desc>
                            After we completed the app concept with exciting
                            graphics and perfectly placed text, we test your app
                            for cross platform compatibility of images, graphics
                            and user experience in real-world scenarios. Once we
                            sniff out and correct all the technical flaws in
                            term of performance, security and interruption ,
                            it’s time to release your app. If you’re confident
                            with your app, we are happy.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageC></ImageC>
            </Container>
        </>
    );
};

export default Development;
