import { useEffect, useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Services from "./Services";
import Design from "./Design";
import Development from "./Development";
import Marketing from "./Marketing";
import Consultancy from "./Consultancy";
import Ml from "./Ml";

const MainContainer = styled.div`
    display: flex;
    padding: 0;
    ${mobile({ padding: "0px" })}
`;

const Container = styled.div`
    padding: 0 3rem;
    flex-grow: 5;
    display: flex;
    grid-gap: 0;
    grid-template-columns: auto auto auto;
    flex-wrap: wrap;
    justify-content: space-between;
    ${mobile({
        display: "grid",
        gridTemplateColumns: "100%",
        padding: "1rem 0 2rem 0",
    })}
`;

const FilterContainer = styled.div`
    display: block;
    width: 100vh;
    flex-grow: 2;
    ${mobile({ display: "none" })}
`;

const FilterTitle = styled.div`
    text-align: left;
    margin: 2rem 3rem;
    font-size: 1.5rem;
    font-weight: 600;
    ${mobile({ display: "none" })}
`;

const FilterItem = styled.div`
    text-align: left;
    margin: 1rem 3rem;
    font-size: 1rem;
    &:hover {
        font-family: "FuturaPT-Demi";
    }
    ${mobile({ display: "none" })}
`;

const Products = ({ cat, filters, sort }) => {
    const location = useLocation();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        cat &&
            setFilteredProducts(
                products.filter((item) =>
                    Object.entries(filters).every(([key, value]) =>
                        item[key].includes(value)
                    )
                )
            );
    }, [products, cat, filters]);

    useEffect(() => {
        if (sort === "newest") {
            setFilteredProducts((prev) =>
                [...prev].sort((a, b) => a.createdAt - b.createdAt)
            );
        } else if (sort === "asc") {
            setFilteredProducts((prev) =>
                [...prev].sort((a, b) => a.myprice - b.myprice)
            );
        } else {
            setFilteredProducts((prev) =>
                [...prev].sort((a, b) => b.myprice - a.myprice)
            );
        }
    }, [sort]);

    return (
        <MainContainer>
            <FilterContainer>
                <FilterTitle>Services</FilterTitle>
                <Link
                    style={
                        location.pathname === "/services/design"
                            ? {
                                  textDecoration: "none",
                                  color: "#1f2f4d",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/services/design"}
                >
                    <FilterItem>Design</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/services/development"
                            ? {
                                  textDecoration: "none",
                                  color: "#1f2f4d",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/services/development"}
                >
                    <FilterItem>Development</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/services/marketing"
                            ? {
                                  textDecoration: "none",
                                  color: "#1f2f4d",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/services/marketing"}
                >
                    <FilterItem>Digital Marketing</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/services/consultancy"
                            ? {
                                  textDecoration: "none",
                                  color: "#1f2f4d",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/services/consultancy"}
                >
                    <FilterItem>Consultancy</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/services/machine-learning"
                            ? {
                                  textDecoration: "none",
                                  color: "#1f2f4d",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/services/machine-learning"}
                >
                    <FilterItem>Machine Learning</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname ===
                        "/services/artificial-intelligence"
                            ? {
                                  textDecoration: "none",
                                  color: "#1f2f4d",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/services/artificial-intelligence"}
                >
                    <FilterItem>Artificial Intelligence</FilterItem>
                </Link>
            </FilterContainer>

            <Container>
                {cat === "design" ? <Design></Design> : cat === "development" ? <Development></Development> : cat === "marketing" ? <Marketing></Marketing> : cat === "consultancy" ? <Consultancy></Consultancy> : cat === "machine-learning" ? <Ml></Ml> : <Services></Services>}
            </Container>
        </MainContainer>
    );
};

export default Products;
