import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    width: 100%;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", gridTemplateColumns: "100%" })}
`;
const Title = styled.h1`
    margin: 0 3rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.5rem",
    })}
`;

const Subtitle = styled.h2`
    margin: 0 3rem;
    font-family: FuturaPT-Demi;
    font-size: 1.5rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.4rem",
        padding: "0rem",
    })}
`;

const AboutContainer = styled.div`
    display: block;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    min-height: 60vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    order: "1";
     ${mobile({ height: "100%", padding: "20px 0" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/d1_UteagqKxtt.png?updatedAt=1696001489937");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/d2_jm7h9oSY6.png?updatedAt=1696001489545");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/d3_t49OCmpvbb.png?updatedAt=1696001489897");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "3",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageD = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/d4_ek3CLNcV9.png?updatedAt=1696001489579");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "4",
        height: "50vh",
        width: "100vw",
    })}
`;



const Design = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>Why Branding Matters?</Title>
                    <AboutContainer>
                        <Subtitle>Stand Out in the Crowd!</Subtitle>
                        <Desc>
                            As millions of businesses trying to get noticed
                            online and offline, branding is important for every
                            company to set itself apart from the competition and
                            define its mission for both customers and employees.
                            Your brand must fits your company uniquely,
                            featuring your strengths and capitalizing on your
                            target audiences. Branding is necessary to form a
                            strong customer bonds, create employee advocates,
                            support marketing effort, drives higher price point
                            and build a loyal customer base.
                        </Desc>
                        <Desc>
                            The appearance of your business matter – brand
                            identity is the visual impression you leave with
                            your customers every time they interact with your
                            brand. The visual vocabulary of your brand needs to
                            communicating effortlessly across all your marketing
                            touch points including: your logo, color palette,
                            typography, imagery, packaging, website, social
                            media, and all other visual elements in your
                            business.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Title>Our Logo Design Process</Title>
                    <Subtitle>
                        Our comprehensive logo design strategy ensures a
                        perfectly crafted logo for your business.
                    </Subtitle>
                    <br></br>
                    <br></br>
                    <Subtitle>1. Exploration</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Whether you are launching a new product or
                            rebranding an existing one, research and strategy
                            comes first. It is important to nail the intention
                            behind your brand. Your brand’s voice and mission
                            must be clear before creating the brands visual
                            identity. We take the time to explore how your brand
                            is perceived by the outside world to avoid lack of
                            identity and affinity. Then, we will have a meeting
                            with key stakeholders to grasp some ideas.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftA>
                    <Subtitle>2. Sketches</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Once the pivot point of your organization is ready,
                            we look at how best to convey that message. With
                            industry leading techniques, we will identify the
                            needs of the customers and core value of your
                            business to reinforce the brand’s essence, promise,
                            and personality. We conceptualize and sketches the
                            initial ideas before send it to you for review and
                            then refine it accordingly to meet your objectives.
                        </Desc>
                    </AboutContainer>
                    <Subtitle>3. Digitization</Subtitle>
                    <AboutContainer>
                        <Desc>
                            The logo is converted into a computerized embroidery
                            format to edit the background, resize it, enhance
                            the visual impact and highlight certain elements
                            within the design. Besides, the qualities and key
                            ideas you are going for with your brand are
                            identified. With logo digitizing, the best part is
                            that even minute details can be captured within the
                            digitized design by exploring the design space using
                            specific stitches to create innovative design.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageC></ImageC>
            </Container>
            <Container>
                <ImageD></ImageD>
                <LeftA>
                    <Subtitle>4. Color</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Different colors shows various emotions and
                            behaviors. Thus, selecting suitable color is a most
                            impactful choice to develop your brand aesthetic.
                            Our system uses an organized approach to apply color
                            to your logo. We measure the accessibility of
                            different color combination and pick the right logo
                            colors that highlight your business’ strengths and
                            help you attract the right customers.
                        </Desc>
                    </AboutContainer>

                    <Subtitle>5. Final Logo</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Once the logo is done, we examine it clearly to
                            ensure that it follows your criteria and send it to
                            you for any alteration. Finally, we do a final
                            retouch to make it highly visual that match the
                            specific assignment based on your satisfaction. We
                            then transfer all necessary information and files in
                            various format to an accessible file for immediate
                            use.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
        </>
    );
};

export default Design;
