import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    width: 100%;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", gridTemplateColumns: "100%" })}
`;
const Title = styled.h1`
    margin: 0 3rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.5rem",
    })}
`;

const Subtitle = styled.h2`
    margin: 0 3rem;
    font-family: FuturaPT-Demi;
    font-size: 1.5rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.4rem",
        padding: "0rem",
    })}
`;

const AboutContainer = styled.div`
    display: block;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    min-height: 60vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    order: "1";
     ${mobile({ height: "100%", padding: "20px 0" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/ml1_ji0FPg_c8H.png?updatedAt=1696001486069");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/ml2_E4yOO72M8.png?updatedAt=1696001486612");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/ml3_vdT_omS0IL.png?updatedAt=1696001485659");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        display: "none",
    })}
`;


const Ml = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>Let Us Help You Accelerate Your Growth</Title>
                    <AboutContainer>
                        <Subtitle>Learn How Machine Learning Can Help You Grow Your Business</Subtitle>
                        <Desc>
                        In today's rapidly evolving business landscape, staying competitive requires harnessing cutting-edge technologies. One such transformative tool is machine learning, a subset of artificial intelligence that enables computers to learn from data and make intelligent decisions. By integrating machine learning into their operations, businesses can unlock a plethora of benefits that can drive growth, efficiency, and innovation.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Subtitle>
                    
1. Enhanced Customer Experience:
                    </Subtitle>
                    <AboutContainer>
                        <Desc>
                        Machine learning enables businesses to analyze customer data to gain valuable insights into preferences, behavior patterns, and buying habits. This information can be used to personalize marketing efforts, recommend products, and even predict future needs. This personalized approach fosters customer loyalty and increases customer satisfaction.
                        </Desc>
                    </AboutContainer>
                    <Subtitle>
                    
             2. Optimized Operations and Efficiency:
                                        </Subtitle>
                                        <AboutContainer>
                                            <Desc>
                                            Automation is a key advantage of machine learning. It can streamline various processes, such as inventory management, supply chain optimization, and resource allocation. By automating routine tasks, businesses can reduce operational costs and allocate resources more efficiently, ultimately leading to increased productivity.
                                            </Desc>
                                        </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftA>
                    <Subtitle>3. Predictive Analytics for Decision-Making:</Subtitle>
                    <AboutContainer>
                        <Desc>
                        Machine learning models excel at predicting future trends based on historical data. This capability is invaluable for making informed business decisions, ranging from inventory planning to financial forecasting. By leveraging predictive analytics, businesses can proactively respond to market changes and gain a competitive edge.
                        </Desc>
                    </AboutContainer>
                    <Subtitle>4. Fraud Detection and Security:</Subtitle>
                    <AboutContainer>
                        <Desc>
                        Machine learning algorithms excel at identifying patterns, which makes them highly effective in detecting anomalies or suspicious activities. In the financial sector, for instance, machine learning is used to detect fraudulent transactions, helping protect businesses and their customers from potential losses.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageC></ImageC>
            </Container>
        </>
    );
};

export default Ml;
