import { useState, useRef } from "react";
import styled from "styled-components";
import { login } from "../redux/apiCalls";
import { mobile } from "../responsive";
import { useDispatch } from "react-redux";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import Newsletter from "../components/Newsletter";
import { ToastContainer } from "react-toastify";
import Footer from "../components/Footer";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.3)
        ),
        url("https://ik.imagekit.io/dnddecpho/Haofa/200523-%E5%93%81%E7%89%8C_%E8%B1%AA%E6%B3%95_%E5%A7%9A%E6%80%BB_1921-%E9%AB%98%E7%AB%AF%E7%B2%BE%E4%BF%AE_2%E4%B8%AA_%E6%89%8B%E6%A8%A103-02_DeUiEikEh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665388006291")
            bottom;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    ${mobile({ display: "block", height: "100%", background: "none" })}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;
    margin: 5rem;
    width: 40%;
    padding: 16px 32px;
    background-color: white;
    min-width: 300px;
    ${mobile({
        width: "85%",
        margin: "0rem",
        height: "100%",
    })}
`;

const Heading = styled.h1`
    font-size: 28px;
    color: #1f2f4d;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    font-family: Tahoma;
`;

const Line = styled.hr`
    width: 20%;
    margin-left: auto;
    margin-right: auto;
`;

const Title = styled.h1`
    font-size: 18px;
    color: #1f2f4d;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
`;

const Form = styled.div`
    display: block;
    flex-wrap: wrap;
    ${mobile({ display: "flex", flexDirection: "column" })}
`;

const Input = styled.input`
    flex: 1;
    min-width: 95%;
    padding: 14px;
    ${mobile({ minWidth: "40%"})}
`;

const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Link = styled.a`
    margin: 20px 0px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    font-family: Tahoma;
`;

const Button = styled.button`
    width: 100%;
    border: none;
    padding: 15px 20px;
    background-color: #1f2f4d;
    color: white;
    cursor: pointer;
    margin: 1rem 0;
    border-radius: 30px;
`;

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const form = useRef();

    const handleOnSubmit = () => {
        login(dispatch, { email, password });
    };
    return (
        <>
            <Navbar></Navbar>
            <Announcement />
            <Container>
                <Wrapper>
                    <Heading>Login</Heading>
                    <Line />

                    <Form ref={form}>
                        <Title>Email</Title>
                        <Input
                            id="grid-first-name"
                            name="login"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Title>Password</Title>
                        <Input
                            id="grid-last-name"
                            name="password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <LinkContainer>
                            <Link href="/register">FORGOT YOUR PASSWORD?</Link>
                        </LinkContainer>
                        <Button onClick={handleOnSubmit}>SIGN IN</Button>
                        <ToastContainer
                            style={{ width: "fit-content", overflow: "hidden" }}
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <LinkContainer>
                            <Link href="/register">CREATE NEW ACCOUNT</Link>
                        </LinkContainer>
                        <LinkContainer>
                            <Link href="/register">RETURN TO STORE</Link>
                        </LinkContainer>
                    </Form>
                </Wrapper>
            </Container>
            <Newsletter></Newsletter>
            <Footer></Footer>
        </>
    );
};

export default Login;
