import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    width: 100%;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", gridTemplateColumns: "100%" })}
`;
const Title = styled.h1`
    margin: 0 3rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.5rem",
    })}
`;

const Subtitle = styled.h2`
    margin: 0 3rem;
    font-family: FuturaPT-Demi;
    font-size: 1.5rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.4rem",
        padding: "0rem",
    })}
`;

const AboutContainer = styled.div`
    display: block;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    min-height: 60vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    order: "1";
     ${mobile({ height: "100%", padding: "20px 0" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/c2_u1QLS3HyM.png?updatedAt=1696001486032");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/c1_VwwcqOovU.png?updatedAt=1696001485368");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const Consultancy = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>Concepts for Effective Business & Consulting</Title>
                    <AboutContainer>
                        <Subtitle>Researching your Target Audience</Subtitle>
                        <Desc>
                        Business consultants provide management consulting to help organizations improve performance and efficiency. These professionals analyze businesses and create solutions while also helping companies meet their goals. Business owners should consider hiring business consultants when they need help or perspective on their chosen path or need a catalyst for change in their companies. Your business is unique to you, though, and the more we can learn about it and your audience, the better we'll be able to create for both.
                        </Desc>
                        <Desc>
                            Before you begin creating social media marketing
                            campaigns, consider your business’s goals. Starting
                            a social media marketing campaign without a social
                            strategy in mind is like wandering around a forest
                            without a map—you might have fun, but you’ll
                            probably get lost.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Subtitle>
                    
Tracking Business Growth and Industry Changes
                    </Subtitle>
                    <AboutContainer>
                        <Desc>
                        Metric tracking is important when what you're dealing with is the nuts and bolts of your business' growth. By tracking developments, report losses, and factor in changes of the industry. For a more effective marketing, a more adaptable company that react fast on environment changes will win the race.
                        </Desc>
                    </AboutContainer>
                    <Subtitle>
                    
                    Time reporting equals real results
                                        </Subtitle>
                                        <AboutContainer>
                                            <Desc>
                                            Business reporting is a professional service. If you don't know what return you're getting on your investment, it becomes less meaningful. You need information on the traffic it generates, how much of that traffic is converting to sales and queries, and how all of that stacks up against your budget.
                                            </Desc>
                                        </AboutContainer>
                </LeftA>
            </Container>
        </>
    );
};

export default Consultancy;
