import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { mobile } from "../responsive";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Container = styled.div``;

const Hero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 45vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "none",
    })}
`;

const MobileHero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 35vh;
    display: none;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "grid"
    })}
`;

const MobileTitleContainer = styled.div`
    display: none;
    grid-auto-flow: column;
    grid-template-column: auto auto auto;
    height: 10vh;
    background: #1f2f4d;
    ${mobile({
        display: "grid",
        width: window.innerWidth 
    })}
`;

const MobileTitle = styled.div`
    font-family: FuturaPT-Demi;
    text-transform: uppercase;
    border: 1px solid white;
    color: white;
    text-align: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mobile({
        width: (window.innerWidth - 10) / 3
    })}
`;

const Title = styled.h1`
    font-family: FuturaPT-Bold;
    text-transform: uppercase;
    background: #1f2f4d;
    color: white;
    font-size: 2.5rem;
    text-align: center;
    padding: 3rem 2rem;
    ${mobile({
        display: "none",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "3rem 2rem 3rem 2rem",
    })}
`;

const ProductList = () => {
    const location = useLocation();
    const cat = location.pathname.split("/")[2];
    // eslint-disable-next-line
    const [filters, setFilters] = useState({});
    // eslint-disable-next-line
    const [sort, setSort] = useState("newest");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Announcement />
            <Navbar />

            <Hero
                style={
                    cat === "design"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/design-banner_78cVXab63.png?updatedAt=1695973142853`}`,
                          }
                        : cat === "development"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/development-banner_kkgFoVTbN.png?updatedAt=1695973142930`}`,
                          }
                        : cat === "marketing"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/marketing_jIapIaqVQ.png?updatedAt=1695898123053`}`,
                          }
                        : cat === "consultancy"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/consultancy-banner_d4-1fTC8-.png?updatedAt=1695985858076`}`,
                          }
                        : cat === "machine-learning"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/ml-banner_ORomkVAml.png?updatedAt=1695973142734`}`,
                          }
                        : {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/ai-banner_vZ2l3oLYU.png?updatedAt=1695973142775`}`,
                          }
                }
            ></Hero>
                        <MobileHero
                style={
                    cat === "design"
                    ? {
                          backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/design-banner_78cVXab63.png?updatedAt=1695973142853`}`,
                      }
                    : cat === "development"
                    ? {
                          backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/development-banner_kkgFoVTbN.png?updatedAt=1695973142930`}`,
                      }
                    : cat === "marketing"
                    ? {
                          backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/marketing_jIapIaqVQ.png?updatedAt=1695898123053`}`,
                      }
                    : cat === "consultancy"
                    ? {
                          backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/consultancy-banner_d4-1fTC8-.png?updatedAt=1695985858076`}`,
                      }
                    : cat === "machine-learning"
                    ? {
                          backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/ml-banner_ORomkVAml.png?updatedAt=1695973142734`}`,
                      }
                    : {
                          backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/misc/ai-banner_vZ2l3oLYU.png?updatedAt=1695973142775`}`,
                      }
            }
            ></MobileHero>
            <Title>
                {cat === "self-winding"
                    ? "Self-Winding Tourbillon"
                    : cat === "manual"
                    ? "Manual Winding Tourbillon"
                    : cat === "crystal"
                    ? "Crystal Series"
                    : cat === "carousel"
                    ? "Carousel Series"
                    : cat === "mechanical"
                    ? "Automatic Mechanical Series"
                    : cat === "machine-learning"
                    ? "Machine Learning"
                    : cat === "artificial-intelligence"
                    ? "Artificial Intelligence"
                    : cat}
            </Title>
            <MobileTitleContainer>
                <MobileTitle
                    style={
                        location.pathname === "/services/design"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "FuturaPT-Demi",
                                  background: "#1f2f4d",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/services/design"
                    >
                        Design{" "}
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/services/development"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "FuturaPT-Demi",
                                  background: "#1f2f4d",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/services/development"
                    >
                        Development
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/services/marketing"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "FuturaPT-Demi",
                                  background: "#1f2f4d",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/services/marketing"
                    >
                        Marketing{" "}
                    </Link>
                </MobileTitle>
            </MobileTitleContainer>
            <MobileTitleContainer>
            <MobileTitle
                    style={
                        location.pathname === "/services/consultancy"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "FuturaPT-Demi",
                                  background: "#1f2f4d",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/services/consultancy"
                    >
                        Consultancy{" "}
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/services/machine-learning"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "FuturaPT-Demi",
                                  background: "#1f2f4d",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/services/machine-learning"
                    >
                       Machine Learning{" "}
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/services/artificial-intelligence"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "FuturaPT-Demi",
                                  background: "#1f2f4d",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/services/artificial-intelligence"
                    >
                        Aritificial Intelligence
                    </Link>
                </MobileTitle>
            </MobileTitleContainer>
            <Products cat={cat} filters={filters} sort={sort} />
            <Newsletter />
            <Footer />
        </Container>
    );
};

export default ProductList;
