import styled from "styled-components";
import { mobile } from "../responsive";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import Newsletter from "../components/Newsletter";
import { useRef, useEffect } from "react";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("https://ik.imagekit.io/dnddecpho/misc/nice_5YGNFp95C.png?updatedAt=1695903213302")
            center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    ${mobile({ display: "block", height: "100%", background: "none" })}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;
    margin: 5rem;
    width: 40%;
    padding: 16px 32px;
    background-color: white;
    min-width: 300px;
    ${mobile({
        width: "90%",
        margin: "0rem",
        height: "100%",
        padding: "3rem 1rem",
    })}
`;

const Title = styled.h1`
    font-size: 35px;
    color: #1f2f4d;
    display: flex;
    align-items: center;
    font-family: FuturaPT-Bold;
`;

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    ${mobile({ display: "flex", flexDirection: "column" })}
`;

const Input = styled.input`
    font-family: FuturaPT-Demi;
    flex: 1;
    min-width: 40%;
    margin: 20px 10px 0px 0px;
    padding: 14px;
`;

const PhoneInput = styled.input`
    font-family: FuturaPT-Demi;
    flex: 1;
    min-width: 90%;
    margin: 20px 10px 0px 0px;
    padding: 14px;
`;

const MessageInput = styled.input`
    font-family: FuturaPT-Demi;
    flex: 1;
    min-width: 90%;
    margin: 20px 10px 0px 0px;
    padding: 30px 14px;

    &:after {
        content: " 🦄";
    }
`;

const Agreement = styled.span`
    font-family: FuturaPT-Demi;
    font-size: 14px;
    margin: 20px 0px;
`;

const Button = styled.button`
    width: 40%;
    border: 1px black solid;
    padding: 15px 20px;
    margin-bottom: 1rem;
    background-color: black;
    color: white;
    cursor: pointer;
    font-family: FuturaPT-Bold;
    &:hover {
        background: #ead61c;
        color: black;
    }
    ${mobile({ width: "70vw" })}
`;

const SERVICE_ID = "service_9x28cq8";
const TEMPLATE_ID = "template_blknfw7";
const USER_ID = "2oRXHzBAa8pjFRl2z";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const form = useRef();
    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
            (result) => {
                toast.success(
                    "We have successfully received your email, Please be patient as we get back to you as soon as possible!",
                    {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            },
            (error) => {
                console.log(error.text);
                toast.error("Failed", {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        );
        e.target.reset();
    };

    return (
        <>
            <Navbar></Navbar>
            <Announcement />
            <Container>
                <Wrapper>
                    <Title>CONTACT US</Title>
                    <Form ref={form} onSubmit={handleOnSubmit}>
                        <Input
                            id="grid-first-name"
                            placeholder="First Name"
                            name="firstName"
                        />
                        <Input
                            id="grid-last-name"
                            placeholder="Last Name"
                            name="lastName"
                        />
                        <PhoneInput
                            id="grid-number"
                            placeholder="Phone Number"
                            name="phoneNumber"
                        />
                        <MessageInput
                            id="grid-message"
                            placeholder="Enter your message here"
                            name="message"
                        />
                        <Agreement>
                            By sending a message, I consent to the processing of
                            my message and understand that it will take
                            approximately 3 working days for us to get back to
                            you
                        </Agreement>
                        <Button>SEND</Button>
                        <ToastContainer
                            style={{ width: "fit-content", overflow: "hidden" }}
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </Form>
                </Wrapper>
            </Container>
            <Newsletter></Newsletter>
            <Footer></Footer>
        </>
    );
};

export default Contact;
