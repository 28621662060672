import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";

const Image = styled.img`
    object-fit: cover;
    opacity: 0.4;
    width: 100vw;
    ${mobile({ height: "80vh" })}
`;

const Header = styled.div`
    height: 80vh;
    width: 100vw;
    background: black;
    overflow: hidden;
    ${mobile({ height: "80vh" })}
`;

const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Bold;
    font-size: 4rem;
    margin: 50px 0;
    color: white;
    ${mobile({ margin: "1rem", textAlign: "center", fontSize: "2rem" })}
`;

const Section = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: "Roboto", sans-serif;
    ${mobile({ top: "50%" })}
`;

const Button = styled.button`
    width: 40%;
    border: 1px black solid;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: black;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: FuturaPT-Bold;
    &:hover {
        background: #ead61c;
        color: black;
    }
    ${mobile({ width: "70vw", display: "none" })}
`;

const ButtonContainer = styled.div`
display: "block"
    ${mobile({ width: "70vw", display: "none" })}
`;

const HomeBannerOverlay = (imageUrl) => {
    return (
        <Header>
            <Image src={imageUrl.imageUrl} alt="About"></Image>{" "}
            <Section>
                <Title>{imageUrl.title}</Title>
                <ButtonContainer>
                    {" "}
                    <Link to="/contact">
                        <Button>Get Quote</Button>
                    </Link>
                </ButtonContainer>
            </Section>
        </Header>
    );
};

export default HomeBannerOverlay;
