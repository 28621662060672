import { useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useHistory } from "react-router-dom";

const MainContainer = styled.div`
    background: #1f2f4d;
    ${mobile({ padding: "0px" })}
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    flex-wrap: wrap;
    overflow: hidden;
    ${mobile({
        padding: "0px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    })}
`;

const MainCategoryContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    ${mobile({ padding: "0px" })}
`;

const CategoryContainer = styled.div`
    background-size: contain;
    background-position: center;
    &:hover {
        transform: scale(1.2);
        -webkit-transition: transform 0.4s ease-in-out;
    }
    ${mobile({ padding: "0px" })}
`;

const Series = styled.p`
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    text-align: center;
    max-width: 50vh;
    font-family: "FuturaPT-Bold";
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1rem",
        padding: "0 1rem",
        marginBottom: "2rem",
    })}
`;

const Title = styled.h1`
    letter-spacing: 0.1rem;
    padding: 5rem 2rem 5rem 2rem;
    font-family: FuturaPT-Bold;
    font-size: 3rem;
    color: white;
    text-align: center;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.8rem",
        padding: "2rem 2rem 2rem 2rem",
    })}
`;

const Category = () => {
    // eslint-disable-next-line
    const [categories, setCategories] = useState([
        {
            url: "https://ik.imagekit.io/dnddecpho/misc/design_eP5tFQ2BZ.png?updatedAt=1695897252228",
            series: "Design",
            cat: "design",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/misc/development_3DSxqdU5A.png?updatedAt=1695898360347",
            series: "Development",
            cat: "development",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/misc/marketing_jIapIaqVQ.png?updatedAt=1695898123053",
            series: "Online Marketing",
            cat: "marketing",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/misc/consultancy_J6ifj8FAk.png?updatedAt=1695897820318",
            series: "Consultancy",
            cat: "consultancy",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/misc/ml_PbVN0223a.png?updatedAt=1695898052362",
            series: "Machine Learning",
            cat: "machine-learning",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/misc/ai_CBear0Ayb.png?updatedAt=1695897820167",
            series: "Artificial Intelligence",
            cat: "artificial-intelligence",
        },
    ]);

    const history = useHistory();

    return (
        <MainContainer>
            <Title>OUR SERVICES</Title>
            <Container>
                {categories.map((item) => (
                    <MainCategoryContainer
                        onClick={() => history.push(`services/${item.cat}`)}
                        style={{
                            background: `url(${item.url})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                        key={`${item.series}`}
                    >
                        <CategoryContainer>
                            <Series>{item.series}</Series>
                        </CategoryContainer>
                    </MainCategoryContainer>
                ))}
            </Container>
        </MainContainer>
    );
};

export default Category;
