import Product from "./pages/Product";
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Login from "./pages/Login";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Cart from "./pages/Cart";
import Delivery from "./pages/Delivery";
import Refund from "./pages/Refund";
import Contact from "./pages/Contact";
import AboutUs from "./pages/About";
import styled from "styled-components";
import ForgotPassword from "./pages/ForgotPassword";
import { ReactPixel } from "react-facebook-pixel";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

const GlobalContainer = styled.div`
    font-family: "FuturaPT-Book";
`;


const tagManagerArgs = {
    gtmId: "G-WWPJVH8YGH",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
    const user = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        const load = async () => {
            try {
                ReactPixel.init(532645418897855, null, {
                    autoConfig: true,
                    debug: true,
                });
                ReactPixel.pageView();
                ReactPixel.track("ViewContent");
            } catch (err) {
                console.log(err);
            }
        };
        load();
        window.dataLayer.push({
            event: "pageview",
            eventProps: {
                category: "home",
                action: "pageview",
                label: "initialized",
                value: 1
            }
        });
    });

    return (
        <GlobalContainer>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/services/:category">
                        <ProductList />
                    </Route>
                    <Route path="/product/:model">
                        <Product />
                    </Route>
                    <Route path="/cart">
                        <Cart />
                    </Route>
                    <Route path="/privacy">
                        <Privacy />
                    </Route>
                    <Route path="/refund">
                        <Refund />
                    </Route>
                    <Route path="/delivery">
                        <Delivery />
                    </Route>
                    <Route path="/terms">
                        <Terms />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route path="/about">
                        <AboutUs />
                    </Route>
                    <Route path="/login">
                        {user ? <Redirect to="/" /> : <Login />}
                    </Route>
                    <Route path="/reset">
                        {user ? <Redirect to="/" /> : <ForgotPassword />}
                    </Route>
                </Switch>
            </Router>
        </GlobalContainer>
    );
};

export default App;
