import styled from "styled-components";
import { mobile } from "../responsive";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Announcement from "../components/Announcement";
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    margin-bottom: 3rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: black;
    ${mobile({ margin:"0" })}
`;
const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Demi;
    font-size: 40px;
    margin: 50px 0;
    background: black;
    color: white;
    ${mobile({ margin: "1rem", textAlign: "center", fontSize: "2rem" })}
`;

const AboutContainer = styled.div`
    padding: 3rem 5rem;
    display: grid;
    background: white;
    ${mobile({ padding: "1rem 3rem" })}
`;

const Heading = styled.h2`
    padding: 1.5rem 3rem;
    font-size: 22px;
    font-weight: 300;
    ${mobile({ textAlign: "left", padding: "0rem" })}
`;

const Desc = styled.div`
    padding: 0 3rem;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 2rem;
    line-height: 1.5;
    ${mobile({ textAlign: "left", margin: "0rem 0rem", padding: "1rem 0rem" })}
`;

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Announcement />
            <Navbar />
            <Container>
                <Title>Terms & Conditions</Title>
                <AboutContainer>
                    <Heading>Terms of Services</Heading>
                    <Desc>
                        All products shown above are available at official
                        Quantract retailers. To ensure authenticity,
                        genuine Quantract products are only sold through
                        official Quantract retailers. Quantract does
                        not sell watches directly from the website and cannot
                        guarantee the authenticity of watches sold online
                        through unofficial channels. Quantract reserves
                        the right to modify the models on its website at any
                        time. While Quantract has attempted to accurately
                        display the shapes and colors of its products on the
                        website, they are primarily dependent on your monitor
                        and may therefore be perceived differently. Any
                        additions or substitutions of parts or accessories not
                        manufactured by Quantract, and any alterations,
                        modifications or other material alterations to or on
                        Quantract products by third parties not authorized
                        by Quantract will void the warranty.
                        Quantract does not approve any modification of
                        Quantract products by unauthorized third parties,
                        including the addition of textual and graphic elements,
                        and any customization activities (such as black coating
                        watches, adding aftermarket diamonds, bezels, dials and
                        bracelets, etc.). These modifications may compromise the
                        quality and integrity of Quantract products. All
                        intellectual property rights such as trademarks, trade
                        names, designs and copyrights are reserved and
                        exclusively owned by Quantract.
                    </Desc>
                    <Heading>Terms of Use</Heading>
                    <Desc>
                        YOUR ACCESS TO AND USE OF THE WEBSITE
                        QuantractSHOP.COM ("SITE") IS SUBJECT TO THE
                        FOLLOWING TERMS AND CONDITIONS AND ALL APPLICABLE LAWS.
                        By accessing and browsing this website, you accept this
                        Terms of Use Agreement without limitation or conditions.
                    </Desc>
                    <Heading>COPYRIGHT AND TRADEMARKS</Heading>
                    <Desc>
                        Quantract owns this website. Quantract owns
                        or owns all wallpapers, icons, characters, artwork,
                        images, graphics, music, text, video, software and other
                        content ("Content") and all HTML, CGI and other code and
                        any Format script ("code"). The content and code of this
                        website are protected by copyright. Except as provided
                        in this Agreement, you may not copy, modify, upload,
                        download, transmit, republish, display for
                        redistribution to third parties for commercial purposes,
                        or otherwise distribute any code from this website
                        without the prior written consent of Quantract or
                        content. You may not use the content or code of this
                        website for any purpose other than those described
                        herein. All names, logos and trademarks appearing on the
                        website are the property of Quantract. You may not
                        use them in any manner that is likely to be confusing or
                        in any other way without the express prior written
                        permission of Quantract. Your failure to comply
                        with this Terms of Use Agreement will constitute a
                        breach of contract and will infringe Quantract's
                        copyrights, trademarks and other proprietary and
                        intellectual property rights. You may only print hard
                        copy portions of this website for non-commercial
                        purposes.
                    </Desc>
                    <Heading>
                        Liability: No Warranties or Representations
                    </Heading>
                    <Desc>
                        While Quantract makes every effort to include
                        accurate and up-to-date information on the website, it
                        makes no warranties or representations about the content
                        of the website provided "as is". TO THE MAXIMUM EXTENT
                        PERMITTED BY LAW, Quantract IS NOT RESPONSIBLE FOR
                        ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE
                        USE, ACCESS, OR INABILITY TO USE THE INFORMATION AND/OR
                        PRODUCTS AVAILABLE ON THIS WEBSITE, OR ARISING OUT OF
                        THE USE OF THIS WEBSITE. In addition, Quantract is
                        responsible for your visit, The invalidity of any term,
                        condition or provision of the Terms of Use Agreement
                        shall not affect the enforceability of that portion of
                        the Terms of Use Agreement that the applicable court
                        finds enforceable.
                    </Desc>
                    <Heading>Privacy</Heading>
                    <Desc>
                        Quantract is not responsible for the content of
                        any website linked to or from this website. Linking to
                        any other website is entirely at the user's own risk.
                        Although Quantract may provide links to other
                        websites on the website, such links are included only as
                        a convenience to users. Quantract MAKES NO
                        REPRESENTATIONS, AND MAKES NO WARRANTIES, EXPRESS OR
                        IMPLIED, AS TO THE ACCURACY, VALIDITY, SUITABILITY,
                        LEGALITY OR OTHERWISE OF ANY MATERIALS OR INFORMATION
                        CONTAINED ON SUCH SITES.
                    </Desc>
                    <Heading>Revise</Heading>
                    <Desc>
                        Quantract may revise the terms and conditions
                        contained in the Terms of Use Agreement at any time by
                        updating this post. You accept any revisions and should
                        periodically visit this page to review the current terms
                        and conditions that apply to your use of this website.
                    </Desc>
                </AboutContainer>
            </Container>
            <Newsletter />
            <Footer />
        </>
    );
};

export default Terms;
