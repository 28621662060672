import axios from "axios";

let BASE_URL = undefined;
if (process.env.NODE_ENV === "production") {
    BASE_URL = "https://test.com/api/";
} else if (process.env.NODE_ENV === "development") {
    BASE_URL = "http://localhost:5000/api/";
}

const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  header: { token: `Bearer ${TOKEN}` },
});
