import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    width: 100%;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", gridTemplateColumns: "100%" })}
`;
const Title = styled.h1`
    margin: 0 3rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.5rem",
    })}
`;

const Subtitle = styled.h2`
    margin: 0 3rem;
    font-family: FuturaPT-Demi;
    font-size: 1.5rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.4rem",
        padding: "0rem",
    })}
`;

const AboutContainer = styled.div`
    display: block;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    min-height: 60vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    order: "1";
     ${mobile({ height: "100%", padding: "20px 0" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/ai1_DQ284rHWDU.png?updatedAt=1696001486619");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/ai3_H-d-UhRmme.png?updatedAt=1696001485647");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/u3_xa_mM0VM7.png?updatedAt=1696002170710");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        display: "none",
    })}
`;

const Services = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>Unleashing the Power of Artificial Intelligence for Business Success</Title>
                    <AboutContainer>
                        <Subtitle>
                       Let the full power of AI help power your business.
                        </Subtitle>
                        <Desc>
                        Artificial Intelligence (AI) has emerged as a game-changing technology, revolutionizing the way businesses operate and interact with their customers. Through the application of advanced algorithms and machine learning, AI enables computers to perform tasks that typically require human intelligence. This transformative technology holds immense potential for businesses across various industries.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Subtitle>1. Automating Repetitive Tasks:</Subtitle>
                    <AboutContainer>
                        <Desc>
                        AI-powered automation can handle mundane, repetitive tasks with precision and efficiency. This frees up valuable human resources to focus on more complex, strategic activities, ultimately increasing productivity and reducing operational costs.
                        </Desc>
                    </AboutContainer>
                    <Subtitle>2. Enhancing Customer Service:</Subtitle>
                    <AboutContainer>
                        <Desc>
                        AI-driven chatbots and virtual assistants are transforming customer service by providing instant, round-the-clock support. They can handle a wide range of inquiries, offer personalized recommendations, and provide consistent service, leading to improved customer satisfaction and loyalty.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftA>
                    <Subtitle>
                        3. Data Analysis and Insights:
                    </Subtitle>
                    <AboutContainer>
                        <Desc>
                        AI excels at processing and analyzing vast amounts of data at high speeds. This capability enables businesses to extract valuable insights, identify patterns, and make data-driven decisions that can shape their strategies, marketing campaigns, and product development efforts
                        </Desc>
                    </AboutContainer>
                    <Subtitle>4. Predictive Analytics for Forecasting:</Subtitle>
                    <AboutContainer>
                        <Desc>
                        AI algorithms are adept at predicting future trends based on historical data. This ability is invaluable for demand forecasting, inventory management, and financial planning. By leveraging predictive analytics, businesses can optimize their operations and stay ahead of market shifts.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageC></ImageC>
            </Container>
        </>
    );
};

export default Services;
