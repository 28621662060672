import {
    MailOutline,
} from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
    padding: 20px 5rem;
    display: flex;
    ${mobile({ flexDirection: "column", padding: "0px" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${mobile({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
`;

const Logo = styled.img`
    max-height: 8vh;
    max-width: 22vh;
    ${mobile({ maxHeight: "15vh" })};
`;
const Desc = styled.p`
    margin: 20px 0px;
`;

const Center = styled.div`
    flex: 0.5;
    padding: 10px 20px;
    ${mobile({ display: "none" })}
`;

const Title = styled.h3`
    margin-bottom: 30px;
    ${mobile({ display: "none" })}
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    flex-wrap: wrap;
`;

const Right = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ backgroundColor: "#1f2f4d", color: "white", padding: "3rem" })}
`;

const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const Payment = styled.img`
    width: 50%;
`;

const CopyrightContainer = styled.div`
    background: #1f2f4d;
    color: white;
    padding: 20px 5rem;
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column", padding: "20px 3rem" })}
`;

const Copyright = styled.div`
    font-size: 0.9rem;
    ${mobile({ fontSize: "0.6rem" })}
`;

const Footer = () => {
    return (
        <>
            <Container>
                <Left>
                    <Logo src="https://ik.imagekit.io/dnddecpho/misc/Yellow_and_White_Modern_Company_Logo__1_-removebg-preview(1)_LxUp6KZL1.png?updatedAt=1695900928946"></Logo>
                    <Desc>
                        Quantract Limited is a cutting-edge digital
                        marketing agency at the forefront of the online
                        landscape. We specialize in crafting dynamic and
                        results-driven marketing strategies tailored to elevate
                        your brand's online presence.
                    </Desc>
                </Left>
                <Center>
                    <Title>Useful Links</Title>
                    <List>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/"
                        >
                            Home
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/terms"
                        >
                            Terms
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/privacy"
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/delivery"
                        >
                            Delivery Policy
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/refund"
                        >
                            Refund Policy
                        </Link>
                    </List>
                </Center>
                <Center>
                    <Title>Services</Title>
                    <List>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/services/design"
                        >
                            Design
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/services/development"
                        >
                            Development
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/services/marketing"
                        >
                            Digital Marketing
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/services/consultancy"
                        >
                            Consultancy
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/services/machine-learning"
                        >
                            Machine Learning
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/services/artificial-intelligence"
                        >
                            Artificial Intelligence
                        </Link>
                    </List>
                </Center>
                <Right>
                    <Title>Contact</Title>
                    <ContactItem>
                        <MailOutline style={{ marginRight: "10px" }} />{" "}
                        quantractmail@gmail.com
                    </ContactItem>
                    <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />
                </Right>
            </Container>
            <CopyrightContainer>
                <Copyright>
                    © 2023 QUANTRACT LIMITED. All Rights Reserved.
                </Copyright>
            </CopyrightContainer>
        </>
    );
};

export default Footer;
