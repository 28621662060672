import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    width: 100%;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", gridTemplateColumns: "100%" })}
`;
const Title = styled.h1`
    margin: 0 3rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.5rem",
    })}
`;

const Subtitle = styled.h2`
    margin: 0 3rem;
    font-family: FuturaPT-Demi;
    font-size: 1.5rem;
    color: #1f2f4d;
    text-align: left;

    ${mobile({
        display: "block",
        textAlign: "start",
        fontSize: "1.4rem",
        padding: "0rem",
    })}
`;

const AboutContainer = styled.div`
    display: block;
    justify-content: center;
    ${mobile({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    min-height: 60vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    order: "1";
     ${mobile({ height: "100%", padding: "20px 0" })};
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/cav1_EFJvz0DVV.png?updatedAt=1696002416185");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/dm3_Qwfq02jzT.png?updatedAt=1696001489528");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/dm1_9xcsyTKSU.png?updatedAt=1696001485664");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageD = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/misc/cav2_6gNiW_U13.png?updatedAt=1696002416086");
    background-size: cover;
    height: 100%;
    background-position: center;
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
    })}
`;

const Marketing = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>Result Oriented Marketing</Title>
                    <AboutContainer>
                        <Subtitle>Create Great Customer Engagement</Subtitle>
                        <Desc>
                            Social media marketing is a great way for businesses
                            of all sizes to reach prospects and customers. Your
                            customers are already interacting with brands
                            through social media, and if you’re not speaking
                            directly to your audience through social platforms
                            like Facebook, Twitter, Instagram, and Pinterest,
                            you’re missing out! Great marketing on social media
                            can bring amazing success to your business, creating
                            devoted brand advocates and even driving leads and
                            sales.
                        </Desc>
                        <Desc>
                            Before you begin creating social media marketing
                            campaigns, consider your business’s goals. Starting
                            a social media marketing campaign without a social
                            strategy in mind is like wandering around a forest
                            without a map—you might have fun, but you’ll
                            probably get lost.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Title>Our Process</Title>
                    <Subtitle>
                        Our competitive Social Media Marketing strategy ensures
                        increase in ranking in different search engines.
                    </Subtitle>
                    <br></br>
                    <br></br>
                    <Subtitle>1. Discovery</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Discovery enables us to formulate robust insights
                            about your business, the requirement for your brand,
                            your potential clients a and your goals. This in
                            turn should then lead to the best solutions
                            possible. Once we have a strong understanding of
                            your brand, products and services, it will enable us
                            to craft digital marketing strategies and campaigns
                            that will drive traffic and sales.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftA>
                    <Subtitle>2. Strategy</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Once you’re in, we can focus on complete
                            implementation strategy that suits you. This
                            includes ad development, marketing design and
                            sharing, keyword usage and backlinking to bring out
                            the best in your profile. We are enthusiastic to
                            start show you the online presence you’ve probably
                            never seen before, to impress your audience.
                        </Desc>
                    </AboutContainer>
                    <Subtitle>3. Campaign</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Along with each step, understand the decision makers
                            who will determine your success. These may be
                            voters, business partners, or public officials.
                            Then, find out who will have the most influence on
                            these decision makers. These are the people you want
                            to reach and activate to help your initiative gain
                            momentum. Warning: avoid naming broad groups such as
                            “the general public,” “voters” or “women.”
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageC></ImageC>
            </Container>
            <Container>
                <ImageD></ImageD>
                <LeftA>
                    <Subtitle>4. Content</Subtitle>
                    <AboutContainer>
                        <Desc>
                            It is relevant to maximize the synergy between
                            content and social media plan in order to build
                            brand equity, create and retain customers and enable
                            sales. Consider keyword research and competitive
                            research to help brainstorm content ideas that will
                            interest your target audience. The bigger and more
                            engaged your audience is on social media networks,
                            the easier it will be for you to achieve every other
                            marketing goal on your list and be the best there
                            for your audience.
                        </Desc>
                    </AboutContainer>

                    <Subtitle>5. Reporting</Subtitle>
                    <AboutContainer>
                        <Desc>
                            Of course, all the optimizing in the world doesn’t
                            mean a thing if you can’t see the difference it’s
                            making to your social media profile’s performance.
                            We will observe and report on what traffic you’re
                            bringing in, which conversions you’re making, and
                            your overall performance. With this information,
                            your company can move forward with the same services
                            or change tactics as necessary. Our report will
                            features your wins and losses, prove the value and
                            ROI of your campaign.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
        </>
    );
};

export default Marketing;
