import { List, Close } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import MobileNavbar from "./MobileNavbar";

const Container = styled.div`
font-family: FuturaPT-Demi;
    height: 80px;
    ${mobile({ height: "inherit" })}
`;

const MenuContainer = styled.div`
    padding-top: 30px;
`;

const Wrapper = styled.div`
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 40% 20% 40%;
    ${mobile({ padding: "10px 0px", display: "none" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
`;

const LeftContainer = styled.div`
    cursor: pointer;
    margin-right: 30px;
    display: flex;
    align-items: center;
`;

const RightContainer = styled.div`
    cursor: pointer;
    margin-right: 30px;
    margin-top: 20px;
    color: white;
    display: flex;
`;

const Center = styled.div`
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Cursor = styled.div`
    cursor: pointer;
    textDecoration: "none",
    color: "black",
`;

const Logo = styled.img`
    max-height: 50px;
    ${mobile({ maxHeight: "4vh", marginLeft: "5vh" })}
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    ${mobile({ flex: 2, justifyContent: "center" })}
`;

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const ModalTitle = styled.h1`
    cursor: pointer;
    margin: 1rem 0rem;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    padding: 0;
    color: gray;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    &:hover {
        padding-bottom: 10px;
        border-bottom: 1px solid #d7ba94;
    }
    ${mobile({ padding: "0 3rem" })}
`;

const SeriesTitle = styled.h1`
    cursor: pointer;
    margin: 0.7rem 0rem;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    padding: 0;
    color: gray;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    &:hover {
        color: #d7ba94;
        padding-bottom: 10px;
        border-bottom: 1px solid #d7ba94;
    }
    ${mobile({ padding: "0 3rem" })}
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 33.33%;
    display: flex;
    z-index: 11;
    ${mobile({ width: "100%", height: "100%" })}
`;

const ModalItemContainer = styled.div`
    border-right: 1px solid black;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2rem 1.5rem 2rem 3.5rem;
    width: 100%;
    background-color: white;
    z-index: 13;
`;

const ScrollContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40px;
    border: none;
    background-color: #1f2f4d;
    color: white;
    overflow: hidden;
`;

const ScrollText = styled.div`
text-align: center;
display: block;
${mobile({ display: "none" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}


`;

const MobileScrollText = styled.div`
text-align: center;
display: none;
${mobile({ display: "block" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
`;

const NavbarAuth = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${mobile({ fontSize: "12px", marginLeft: "10px", display: "none" })}
    &.hidden {
        display: none;
    }
    &:hover {
        text-decoration: underline;
    }
`;

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showSeries, setShowSeries] = useState(false);

    useEffect(() => {
        if (showMenu === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [showMenu]);

    return (
        <>
            <Container>
                <Wrapper>
                    {" "}
                    <Left>
                        <Cursor>
                            <ul onClick={() => setShowMenu(!showMenu)}>
                                {showMenu ? (
                                    <LeftContainer>
                                        <Close></Close>Menu
                                    </LeftContainer>
                                ) : (
                                    <LeftContainer>
                                        <List></List>Menu
                                    </LeftContainer>
                                )}
                            </ul>
                        </Cursor>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/"
                        >
                            <NavbarAuth>Home</NavbarAuth>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/about"
                        >
                            <NavbarAuth>About</NavbarAuth>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/contact"
                        >
                            <NavbarAuth>Contact</NavbarAuth>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/services/design"
                        >
                            <NavbarAuth>Design</NavbarAuth>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/services/development"
                        >
                            <NavbarAuth>Development</NavbarAuth>
                        </Link>

                    </Left>
                    <Center>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/"
                        >
                            <Logo
                                src={
                                    "https://ik.imagekit.io/dnddecpho/misc/Yellow_and_White_Modern_Company_Logo__1_-removebg-preview(1)_LxUp6KZL1.png?updatedAt=1695900928946"
                                }
                            ></Logo>
                        </Link>
                    </Center>
                    <Right>
                    <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/services/marketing"
                        >
                            <NavbarAuth>Marketing</NavbarAuth>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/services/consultancy"
                        >
                            <NavbarAuth>Consultancy</NavbarAuth>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/services/machine-learning"
                        >
                            <NavbarAuth>Machine Learning</NavbarAuth>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/services/artificial-intelligence"
                        >
                            <NavbarAuth>Artificial Intelligence</NavbarAuth>
                        </Link>
                      
                    </Right>
                </Wrapper>
                <MobileNavbar></MobileNavbar>
                {showMenu && (
                    <OverlayContainer>
                        <ModalContainer></ModalContainer>
                        <Overlay>
                            <ModalDetails>
                                <ModalItemContainer>
                                    <LeftContainer
                                        onClick={() => setShowMenu(false)}
                                    >
                                        <Close></Close>Close
                                    </LeftContainer>
                                    <MenuContainer>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>Home</ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/about"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>About Us</ModalTitle>
                                        </Link>
                                        <ModalTitle
                                            onClick={() => {
                                                setShowSeries(!showSeries);
                                            }}
                                            style={
                                                showSeries
                                                    ? {
                                                          color: "black",
                                                          fontFamily:
                                                              "FuturaPT-Demi",
                                                      }
                                                    : {}
                                            }
                                        >
                                            Services
                                        </ModalTitle>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/contact"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>Contact</ModalTitle>
                                        </Link>
                                    </MenuContainer>
                                </ModalItemContainer>
                            </ModalDetails>
                            <ModalDetails>
                                {showSeries && (
                                    <ModalItemContainer>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/services/design"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setShowSeries(false);
                                            }}
                                        >
                                            <SeriesTitle>Design</SeriesTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/services/development"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setShowSeries(false);
                                            }}
                                        >
                                            <SeriesTitle>
                                                Development
                                            </SeriesTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/services/marketing"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setShowSeries(false);
                                            }}
                                        >
                                            <SeriesTitle>
                                                Digital Marketing
                                            </SeriesTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/services/consultancy"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setShowSeries(false);
                                            }}
                                        >
                                            <SeriesTitle>
                                                Consultancy
                                            </SeriesTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/services/machine-learning"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setShowSeries(false);
                                            }}
                                        >
                                            <SeriesTitle>
                                                Machine Learning
                                            </SeriesTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/services/artificial-intelligence"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setShowSeries(false);
                                            }}
                                        >
                                            <SeriesTitle>
                                                Artificial Intelligence
                                            </SeriesTitle>
                                        </Link>
                                    </ModalItemContainer>
                                )}
                            </ModalDetails>
                            <ModalDetails onClick={() => setShowMenu(false)}>
                                <Left></Left>
                                <RightContainer>
                                    <Close></Close>Close
                                </RightContainer>
                            </ModalDetails>
                        </Overlay>
                    </OverlayContainer>
                )}
            </Container>
            <ScrollContainer>
                <ScrollText>
                    QUANTRACT PROMOTION - FREE consultation for digital
                    marketing and development, Valid until Dec 2023 only.
                </ScrollText>
                <MobileScrollText>
                FREE consultation for digital services.
                </MobileScrollText>
            </ScrollContainer>
        </>
    );
};

export default Navbar;
