import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    List,
    Close,
} from "@material-ui/icons";
import { useState } from "react";
import { mobile } from "../responsive";

const Container = styled.div``;

const MenuContainer = styled.div`
    display: none;
    ${mobile({
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        background: "white",
        color: "black",
    })}
`;

const Dropddown = styled.div`
    padding-top: 15px;
    height: 100vh;
    color: black;
    display: flex;
    flex-direction: column;
`;

const NavbarAuth = styled.div`
    &.hidden {
        display: none;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.img`
    width: 80%;
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

const Center = styled.div`
    font-size: 1.5rem;
    cursor: pointer;
    font-family: FuturaPT-Bold;
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

const MobileNavbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showSeries, setShowSeries] = useState(false);
    return (
        <>
            <Container>
                {" "}
                <MenuContainer>
                    {" "}
                    <Left>
                        {" "}
                        {showMenu ? (
                            <Close
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                            ></Close>
                        ) : (
                            <>
                                <List
                                    onClick={() => {
                                        setShowSeries(false);
                                        setShowMenu(true);
                                    }}
                                ></List>
                            </>
                        )}
                    </Left>
                    <Center>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/"
                        >
                            <LogoContainer>
                            <Logo
                                src={
                                    "https://ik.imagekit.io/dnddecpho/misc/Yellow_and_White_Modern_Company_Logo__1_-removebg-preview(1)_LxUp6KZL1.png?updatedAt=1695900928946"
                                }
                            ></Logo>
                            </LogoContainer>
                        </Link>
                    </Center>
                    <Right>
                    </Right>
                </MenuContainer>
                {showMenu && (
                    <Container>
                        <Dropddown>
                            {" "}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Home
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/about"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                About Us
                            </Link>
                            <NavbarAuth
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowMenu(false);
                                    setShowSeries(true);
                                }}
                            >
                                Services
                            </NavbarAuth>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/contact"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Contact
                            </Link>
                        </Dropddown>
                    </Container>
                )}
                {showSeries && (
                    <Container>
                        <Dropddown>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/services/design"
                            >
                                Design
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/services/development"
                            >
                                Development
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/services/marketing"
                            >
                                Marketing
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/services/consultancy"
                            >
                               Consultancy
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/services/artificial-intelligence"
                            >
                               Artificial Intelligence
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/services/machine-learning"
                            >
                                Machine Learning
                            </Link>
                        </Dropddown>
                    </Container>
                )}
            </Container>
        </>
    );
};

export default MobileNavbar;
